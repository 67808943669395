import React, { useRef } from "react";

import { Contact } from "../../types";
import { Button } from "../Button";
import { Checkbox } from "../Checkbox";
import { Form, FormItem, useForm } from "../Form";
import { Link } from "../Link";
import { useNotification } from "../Notification";
import { TextField } from "../TextField";

import styles from "./ContactForm.module.scss";

interface Props {
  initialState: Contact;
  onClose?: () => void;
}

export function ContactForm({ initialState, onClose }: Props) {
  const formRef = useRef<HTMLFormElement>(null);
  const { showMessage } = useNotification();
  const { state, onFieldChange, errors, validate, reset } = useForm<Contact>({
    initialState,
    formRef,
  });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    const response = await fetch("/api/contact", {
      method: "POST",
      body: JSON.stringify(state),
    });

    if (!response.ok) {
      showMessage({ message: "Įvyko klaida. Bandykite dar kartą", hideAfter: 4000, severity: "error" });
      return;
    }

    showMessage({ message: "Ačiū už jūsų žinutę! Mes susisieksime su jumis kuo greičiau", hideAfter: 4000 });
    reset();
    onClose && onClose();
  };

  const termsLabel = (
    <>
      Sutinku su <Link href="/privacy-policy">privatumo politika</Link>
    </>
  );

  return (
    <Form onSubmit={onSubmit} ref={formRef}>
      <div className={styles.FormContent}>
        <FormItem>
          <TextField
            required
            name="name"
            label="Jūsų vardas"
            value={state.name}
            onChange={onFieldChange("name")}
            type="text"
            error={errors.name}
          />
        </FormItem>
        <FormItem>
          <TextField
            name="phone"
            label="Tel. numeris"
            value={state.phone}
            onChange={onFieldChange("phone")}
            type="text"
            error={errors.phone}
          />
        </FormItem>
        <FormItem>
          <TextField
            name="email"
            label="El. paštas"
            value={state.email}
            onChange={onFieldChange("email")}
            type="email"
            required
            error={errors.email}
          />
        </FormItem>
        <FormItem>
          <TextField
            name="message"
            label="Jūsų žinutė"
            value={state.message}
            onChange={onFieldChange("message")}
            type="text"
            error={errors.message}
            required
          />
        </FormItem>
      </div>
      <div className={styles.LastRow}>
        <FormItem actions>
          <Checkbox
            name="terms"
            required
            onChange={onFieldChange("terms")}
            checked={state.terms}
            label={termsLabel}
            error={errors.terms}
          />
          <Button theme="orange">Siųsti</Button>
        </FormItem>
      </div>
    </Form>
  );
}