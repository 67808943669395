
import { ContactForm } from "components/ContactForm";
import { Pattern } from "components/Pattern/Pattern";
import { Contact } from "types";

import styles from "./ContactUs.module.scss";

export function ContactUs() {
  const initialState: Contact = {
    name: "",
    phone: "",
    email: "",
    message: "",
    terms: false,
    target: "contact-us",
  };

  return (
    <div className={styles.Container}>
      <div className={styles.LeftSide}>
        <div className={styles.Header}>
          <h2>Susisiekite</h2>
        </div>
        <div className={styles.ContactInfo}>
          <div>+370 622 33455</div>
          <div>emilija@raffine.lt</div>
        </div>
        <ContactForm initialState={initialState} />
      </div>
      <div className={styles.RightSide}>
        <Pattern theme="cream" />
      </div>
    </div>
  );
}
