import classNames from "classnames";
import React from "react";

import { useTheme } from "foundation/Theme";
import { themeStyleName } from "styles";

import styles from "./ProductDescription.module.scss";

interface Props {
  description: string;
}

export const ProductDescription: React.FC<Props> = ({ description }) => {
  const theme = useTheme();

  return (
    <div
      className={classNames(styles.ProductDescription, themeStyleName(theme, 'ProductDescription'))}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};
