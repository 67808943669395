import classNames from "classnames";

import styles from "./Arrow.module.scss";
import { ArrowIcon } from "./images";

interface Props {
  direction?: "left" | "right";
  hidden?: boolean;
}

export function Arrow({ direction = "left", hidden = false }: Props) {
  return (
    <div className={classNames(styles.Conteiner, { [styles.Hidden]: hidden })}>
      <ArrowIcon className={classNames(styles.Arrow, styles[`Arrow_${direction}`])} />
    </div>
  );
}
