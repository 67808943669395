
import { ContactForm } from "components/ContactForm";
import { Hamburger } from "components/Hambuger";
import { useModal } from "components/Modal";
import { Page } from "components/Page";
import { ThemeProvider } from "foundation/Theme";
import { Contact } from "types";

import styles from "./ContactModal.module.scss";

interface Props {
  productId?: string;
  topic?: string;
}

export function ContactModal({ productId, topic }: Props) {
  const { close } = useModal();

  const initialState: Contact = {
    name: "",
    phone: "",
    email: "",
    message: "",
    terms: false,
    target: "contact-course-modal",
    topic,
    productId,
  };

  const title = topic ? `Užklausa apie "${topic}"` : "Susiekti su mumis";

  return (
    <ThemeProvider theme="cream">
      <Page>
        <div className={styles.Container}>
          <div className={styles.Header}>
            <div className={styles.Title}>
              <h4>{title}</h4>
            </div>
            <div className={styles.Action}>
              <Hamburger open onClick={close} />
            </div>
          </div>
          <div className={styles.Content}>
            <ContactForm initialState={initialState} onClose={close} />
          </div>
        </div>
      </Page>
    </ThemeProvider>
  );
}
