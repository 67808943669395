import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import classNames from "classnames";

import { useProducts } from "src/context";

import { useMedia } from "../../hooks/useMedia";
import { Arrow } from "../Arrow/Arrow";

import styles from "./Courses.module.scss";
import { ProductCard } from "./components";

export function Courses() {
  const products = useProducts();
  const [currentSlide, setCurrentSlide] = useState(0);
  const { desktop } = useMedia();

  const hasNext = products?.length && currentSlide < products?.length - 1;

  const prevPage = () => {
    if (currentSlide - 1 > -1) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const nextPage = () => {
    if (hasNext) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const updateCurrentSlide = (index: number) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  if (!products || products.length === 0) {
    return null;
  }

  const coursesMarkup = products?.map((product, index) => {
    return <ProductCard key={product.id} number={index + 1} product={product} />;
  });

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <h2>Kursai ir pamokos</h2>
      </div>
      <div className={styles.Content}>
        <div onClick={prevPage} className={classNames(styles.ProductsButton, styles.PrevProduct)}>
          <Arrow direction="left" hidden={currentSlide == 0} />
        </div>
        <div onClick={nextPage} className={classNames(styles.ProductsButton, styles.NextProduct)}>
          <Arrow direction="right" hidden={!hasNext} />
        </div>
        <Carousel
          centerMode
          centerSlidePercentage={desktop ? 70 : 90}
          infiniteLoop={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={currentSlide}
          onChange={updateCurrentSlide}
          showArrows={false}
          showThumbs={false}
          swipeScrollTolerance={20}
          preventMovementUntilSwipeScrollTolerance
        >
          {coursesMarkup}
        </Carousel>
      </div>
    </div>
  );
}
