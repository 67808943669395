import Image from "next/image";
import Link from "next/link";

import { ProductDescription } from "components//ProductDescription";
import { Product, ProductType } from "types/schema";
import { PRODUCT_IMAGES } from "src/constants";

import { Button } from "../../../Button";
import { useModal } from "../../../Modal";
import { ContactModal } from "../ContactModal";

import styles from "./ProductCard.module.scss";

const TYPE_TRANSLATIONS = {
  [ProductType.SEMINAR]: "Seminaras",
  [ProductType.COURSE]: "Kursas",
  [ProductType.EVENT]: "Zoom Seminaras",
};

interface Props {
  product: Product;
  number: number;
}

type ActionElement = ({ product }: { product: Product }) => JSX.Element;

const DefaultAction: ActionElement = ({ product }) => {
  return (
    <Link href={`product/${product.id}`}>
      <Button responsive>Užsiregistruoti</Button>
    </Link>
  );
};

const SeminarActions: ActionElement = ({ product }) => {
  const { show } = useModal();

  const modal = <ContactModal topic={product.title} />;

  const onClick = () => {
    show(modal);
  };

  return (
    <>
      <Button responsive priority="primary" onClick={onClick}>
        Susisiekti
      </Button>
      <Link href={`product/${product.id}`}>
        <Button responsive>Detaliau</Button>
      </Link>
    </>
  );
};

const actions: { [key in ProductType]?: ActionElement } = {
  [ProductType.SEMINAR]: SeminarActions,
};

export function ProductCard({ product, number }: Props) {
  const event = product.event;
  const { title, description, type, price, imageUrl } = product;

  const duration = event?.duration || 0;

  const Actions = actions[type] || DefaultAction;

  return (
    <div className={styles.Container}>
      <div className={styles.Content}>
        <div className={styles.LeftSide}>
          <Image fill src={PRODUCT_IMAGES[imageUrl]} alt={title} className={styles.Image} />
        </div>
        <div className={styles.RightSide}>
          <ProductDescription description={description} />
          <div>
            <div className={styles.ProductInfo}>
              {TYPE_TRANSLATIONS[type]} • {duration ? `${duration} MIN.` : null} • nuo {price} Eur{" "}
            </div>
            <div className={styles.Actions}>
              <Actions product={product} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
